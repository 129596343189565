import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * This component automatically scrolls the window to 0,0 when the location changes.
 */
const ScrollToTop = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
