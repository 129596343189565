import { useEffect } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { setDocumentTitle } from '../Util';
import Config from '../Config';
import { Link } from 'react-router-dom';

export default function Datapolicy() {
  useEffect(() => {
    setDocumentTitle('Datenschutz');
  }, []);

  return (
    <div>
      <Navbar />

      <div className="innerpage-banner center bg-overlay-dark-7 py-7">
        <div className="container">
          <div className="row all-text-white">
            <div className="col-12">
              <h1 className="innerpage-title">Datenschutz</h1>

              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to={Config.LINKS.IMPRINT}>Impressum</Link>
                </li>
                <li className="breadcrumb-item active">Datenschutz</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="container py-5" style={{ fontSize: '1rem' }}>
        <span className="small">
          Zuletzt bearbeitet am: <span>2021-08-08</span>
        </span>

        <h5 className="my-3">Welche Daten werden von uns gesammelt bzw. erhoben?</h5>

        <p>
          Wenn Sie unsere Internetseite besuchen wird ua. Ihre IP-Adresse gespeichert. Wenn Sie uns Kontaktdaten zukommen lassen, werden diese
          ebenfalls gespeichert. Nach Art. 6 Abs. 1 lit. a oder Art. 6 Abs. 1 lit. b DSGVO sind wir zu folgendem berechtigt:
        </p>

        <h6>Server-Logfiles</h6>

        <p>
          Wenn Sie unsere Internetseite aufrufen, werden automatisch bestimmte Daten an uns übermittelt und in sogenannten Logfiles gespeichert. Dazu
          zählen u.a. IP-Adresse, Informationen über den verwendeten Browser, Betriebssystem, Datum und Uhrzeit und HTTP-REFERRER (Internetseite über
          die Sie auf unsere gekommen sind).
        </p>

        <h6>Kontaktdaten</h6>

        <p>
          Wenn Sie uns eine Anfrage per E-Mail zukommen lassen, werden bestimmte Daten an uns übermittelt. Dazu zählen u.a. IP-Adresse, Ihr Name, Ihre
          E-Mail, ggf. weitere Angaben, die Sie von sich aus getätigt haben.
        </p>

        <h5 className="my-3">An wen geben wir Ihre Daten weiter?</h5>

        <p>
          Neben der verantwortlichen Stelle haben ggf. auch externe Dienstleister von uns Zugriff auf Ihre Daten. In einzelnen Fällen können auch
          Dritte wie Behörden und externe Berater Ihre Daten erhalten.
        </p>

        <p>
          <b>HINWEIS</b>: Wenn Sie auf einer unserern verschiedenen Applikationen im Internet gestoßen sind, zu welcher sie nicht ausdrücklich
          eingeladen wurden, bitten wir Sie jene Seite sofort zu verlassen. Diese Applikationen sind, wenn nicht anders hingewiesen, nicht für den
          öffentlichen Gebrauch gedacht und unterliegen gesonderten Datenschutzbestimmungen.
        </p>
      </section>

      <Footer />
    </div>
  );
}
