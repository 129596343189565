export default class Config {
  /**
   * Object that holds the router paths.
   */
  public static LINKS = {
    HOME: '/',
    IMPRINT: '/impressum',
    DATAPOLICY: '/datenschutz',
  };
}
