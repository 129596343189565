import { useEffect } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Company from '../Company.json';
import { getMailToUri, setDocumentTitle } from '../Util';

import background from '../img/background.jpg';
import mini01 from '../img/mini/01.jpg';
import mini02 from '../img/mini/02.jpg';
import mini03 from '../img/mini/03.jpg';
import mini04 from '../img/mini/04.jpg';
import service from '../img/service.jpg';

export default function Home() {
  useEffect(() => {
    setDocumentTitle('Home');
  }, []);

  return (
    <div>
      <Navbar />

      <section
        className="p-0 height-600 parallax-bg"
        style={{
          background: `url(${background}) no-repeat 65% 0%`,
          backgroundSize: 'cover',
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-between align-items-center h-100 all-text-white">
            <div className="col-md-8">
              <h4>{Company.name}</h4>
              <h1 className="display-4 font-weight-bold">Moderne Elektronische Datenverarbeitung</h1>
              <span>Wir bieten Ihnen innovative und funktionale Lösungen für die manuelle Datenerfassung in Ihrem Unternehmen.</span>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-3">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6">
            <h2 className="h1">
              Erfüllen Sie Ihre Ziele
              <br />
              gemeinsam mit uns
            </h2>
            <h5 className="mt-4"> Erfahrenes Familienunternehmen mit großen Zielen </h5>
            <p>
              Wir bieten Ihnen innovative und funktionale Lösungen für die manuelle Datenerfassung Ihres Unternehmens. Gemeinsam erarbeiten wir mit
              Ihnen Lösungen, um manuelle Aufgaben outsourcen und optimieren zu können.
            </p>
            <p>
              Unser Ziel ist es so unbürokratisch, unkompliziert, angenehm und schnell wie möglich mit ihnen zu Arbeiten. <br />
              Wir wurden bereits auch als Feuerwehr <i className="fas fa-fire-extinguisher text-warning"></i> bezeichnet, als ein Auftrag besonders
              schnell und kurzfristig erledigt werden musste :)
            </p>
          </div>

          <div className="col-md-6">
            <div className="row mt-4 mt-md-0">
              <div className="col-7 pl-5 pr-2 mb-3">
                <img className="border-radius-3" src={mini01} alt="" />
              </div>
              <div className="col-5 align-self-end pl-2 mb-3">
                <img className="border-radius-3" src={mini02} alt="" />
              </div>
              <div className="col-5 offset-1 pl-5 pr-2 mb-3">
                <img className="border-radius-3" src={mini03} alt="" />
              </div>
              <div className="col-5 px-2 mb-3">
                <img className="border-radius-3" src={mini04} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-white bg-dark">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <img className="rounded shadow" src={service} alt="" />
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <h1 className="text-white">Was unseren Service auszeichnet?</h1>

              <p className="mb-0">
                Wir wissen, dass jedes Projekt einzigartig und individuell ist. Wir begleiten Sie von Anfang an entsprechend Ihren Anforderungen.
              </p>

              <ul className="list-group list-group-borderless list-group-icon-primary-bg my-3">
                <li className="list-group-item">
                  <i className="fas fa-check"></i> Proprietäre Software (in Haus entwickelt)
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check"></i> Fundiertes Verstehen von Prozessen und Nutzungszwecken
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check"></i> Große und kleine Abnahmemengen <span className="ml-1 text-warning">*</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check"></i> Den Willen für ausgezeichnete Qualität
                </li>
              </ul>

              <small className="mt-2">
                <span className="mr-1 text-warning">*</span>
                Abhänging von der Bearbeitungsdauer eines einzelnen Datensatzes. Wenn wir davon ausgehen, dass eine Datensatzbearbeitung im
                Durchschnitt bei 30 Sekunden liegt, können wir tägliche Abnahmemengen von bis zu 5.000 Datensätzen akzeptieren. <br />
                Die Untergrenze bemisst sich einzig und allein am Kosten-/Leistungsaufwand des jeweiligen Projektes.
              </small>
            </div>
          </div>
        </div>
      </section>

      <section className="" id="more">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div className="title text-center">
                <span className="pre-title">... Sie bringen die Daten, wir die Ergebnisse ...</span>
                <h2> Lassen Sie Ihre Daten Digital werden </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-1">
              <div className="feature-box f-style-5 h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="fas fa-file-audio"></i>
                </div>
                <h3 className="feature-box-title"> Audio </h3>
                <p className="feature-box-desc">Verarbeitung von Audiodateien - Transkription, Codierung, Protokollierung uvm.</p>
              </div>
            </div>
            <div className="col-md-4 mt-1">
              <div className="feature-box f-style-5 h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="fas fa-pencil-alt"></i>
                </div>
                <h3 className="feature-box-title"> Schrift </h3>
                <p className="feature-box-desc">Verarbeitung von handschriftlichen (oder Print) Bilddateien - Adresserfassung, Codierung uvm.</p>
              </div>
            </div>
            <div className="col-md-4 mt-1">
              <div className="feature-box f-style-5 h-100 icon-grad">
                <div className="feature-box-icon">
                  <i className="fas fa-eye"></i>
                </div>
                <h3 className="feature-box-title"> Visuell </h3>
                <p className="feature-box-desc">Verarbeitung von Videomaterial für Metadatenauswertungen, Protokollierung uvm.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="text-center">
            <h1 className="title">Projektarten</h1>
            <p>Wir haben bereits mit allerlei Arten von Projekten zu tun gehabt und schrecken auch nicht vor neuen Herausforderungen zurück.</p>
          </div>

          <p>
            Hauptsächlich arbeiten wir mit "Tagesprojekten". Diese definieren wir in sofern, dass wir von unserem Kunden über den Tag verteilt
            Datensätze zugesendet bekommen, sei es per E-Mail, SFTP oder auch per REST-API, und diese entweder sofort mit minimaler Verzögerung (z.B.
            geeignet für Bestellungseingänge per Fax) oder in / nach einem gewissen Zeitraum bearbeiten (z.B. alles vom Vortag am nächsten Tag
            liefern, besonders geeignet für telefonisch hinterlassene Kundenbewertungen bei z.B. Versicherungen oder Strom-, Gas- und
            Internetanbietern).
          </p>

          <p>
            Außerdem arbeiten wir auch des öfteren mit "Einmalaufträgen", wo der Kunde uns eine enorm große Menge an Datensätze auf einmal zukommen
            lässt und wir diese bis zu einem gewissen Datum bearbeiten sollen. Diese Art Projekte sind meisten Digitalisierungen von alten
            Aktenarchiven oder Postkartenaktionen (z.B. Gewinnspiele). Bei diesem Projekttyp bieten wir auch das Einscannen von möglichen Akten,
            Postkarten etc. an. Diese Leistung können wir zur Zeit leider noch nicht für Tagesprojekte erbringen.
          </p>
        </div>
      </section>

      <section className="bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div className="title text-center all-text-white">
                <span className="pre-title">Wie ein Projektstart aussieht...</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-2 all-text-white primary-border-left-dotted py-4">
              <h6 className="text-primary">01</h6>
              <h4>Solution Architecture</h4>
              <p>Definieren Sie gemeinsam mit uns Ihre Verwendungzwecke und Ziele mit den Daten.</p>
              <ul className="list-unstyled font-weight-bold">
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Zielsetzung des Projekts
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Ersten Workflow konzipieren
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Mögliche Automatisierungen anpeilen (Spracherkennung, OCR etc.)
                </li>
              </ul>
            </div>
            <div className="col-md-4 mt-2 all-text-white primary-border-left-dotted py-4">
              <h6 className="text-primary">02</h6>
              <h4>Sketching</h4>
              <p>Wir erklären Ihnen die notwendigen Softwarebausteine und deren Funktion im Arbeitsprozess.</p>
              <ul className="list-unstyled font-weight-bold">
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Softwaremodell ausarbeiten
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Datenimport &amp; -export
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Erschaffung neuer Schnittstellen für Sie
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Integrationen mit vorhanden Schnittstellen
                </li>
              </ul>
            </div>
            <div className="col-md-4 mt-2 all-text-white primary-border-left-dotted py-4">
              <h6 className="text-primary">03</h6>
              <h4 className="mb-2"> Entwicklung </h4>
              <p>Unser Entwicklerteam baut innerhalb weniger Tage unseren ersten Prototypen und führt Testphasen durch.</p>
              <ul className="list-unstyled font-weight-bold">
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Schnelle Entwicklungsphase
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Alpha- / Beta- Testphasen
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Fehlerbehebungen
                </li>
                <li>
                  <i className="fas fa-angle-right mr-2"></i>
                  Endkontrolltests mit Ihnen durchführen
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <h5 className="text-primary"> Zusätzlich bieten wir: </h5>
            <h2 className="h1">Transkriptions- &amp; Korrekturservice</h2>
            <p>
              Lassen Sie Ihre Audioaufnahmen ganz einfach von unserem Team transkribieren, ohne sich groß Gedanken darüber machen zu müssen. Erhalten
              Sie eine Wort-für-Wort Transkription oder ein Protokoll in kürzester Zeit zurück.
            </p>

            <div className="bg-light p-4 p-lg-5">
              <span className="display-5 icon-primary">
                <i className="fas fa-chalkboard-teacher"></i>
              </span>
              <h5> Fachbereiche </h5>
              <p className="mb-0">
                Unser Team ist in medizinischen, psychologischen, psychologisch-forensischen, juristischen und behördlichen Begrifflichkeiten und
                sprachlichen Besonderheiten geschult.
              </p>
            </div>
          </div>

          <div className="col-md-4 mt-5">
            <div className="bg-light p-4 p-lg-5 mb-5">
              <span className="display-5 icon-primary">
                <i className="fas fa-i-cursor"></i>
              </span>
              <h5> Transkriptarten </h5>
              <p className="mb-0">
                Wir transkribieren Ihre Aufträge in Form von Berichten, Gutachten, Interviews und Diktaten im digitalen und analogen Audioformat.
              </p>
            </div>

            <div className="bg-light p-4 p-lg-5 mb-5">
              <span className="display-5 icon-primary">
                <i className="fas fa-file-alt"></i>
              </span>
              <h5> Eigene Vorlagen </h5>
              <p className="mb-0">Wir arbeiten gerne mit Ihren Vorlagen oder erstellen Ihnen auf Wunsch solche gerne.</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="bg-grad all-text-white p-4 p-lg-5 mb-5">
              <span className="display-5">
                <i className="fas fa-tachometer-alt"></i>
              </span>
              <h5> Schnelligkeit </h5>
              <p className="mb-0">
                Wir arbeiten immer absolut akkurat und schnell und gehen dabei jederzeit gerne auf Ihre besonderen Servicewünsche ein.
              </p>
            </div>

            <div className="bg-light p-4 p-lg-5 mb-5">
              <span className="display-5 icon-primary">
                <i className="fas fa-hands-helping"></i>
              </span>
              <h5> Hilfestellungen </h5>
              <p className="mb-0">Sie haben Fragen und wünsche zu Ihrem Auftrag? Wir helfen Ihnen gerne jederzeit weiter.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 p-sm-5 text-center bg-light">
        <div className="container">
          <div className="px-0 px-sm-5">
            <h2> Haben Sie Fragen und / oder möchten Referenzen? </h2>
            <p className="m-0 px-0 px-md-5">
              Wir bemühen uns innerhalb kürzester Zeit auf Ihre Nachrichten mit genau den richtigen Dingen zu antworten und Sie an den richtigen
              Ansprechpartner weiterzuleiten. Auf Grund von internen Datenschutzrichtlinien geben wir Referenzen nur auf Anfrage aus.
            </p>
          </div>
          <a href={getMailToUri()} className="btn btn-grad mb-0 mt-3 mr-3">
            Jetzt per E-Mail Anschreiben
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
}
