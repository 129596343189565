import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './scss/app.scss';
import Config from './Config';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import ScrollToTop from './components/ScrollToTop';
import Datapolicy from './pages/Datapolicy';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path={Config.LINKS.HOME}>
            <Home />
          </Route>
          <Route path={Config.LINKS.IMPRINT}>
            <Imprint />
          </Route>
          <Route path={Config.LINKS.DATAPOLICY}>
            <Datapolicy />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
