import Company from '../Company.json';
import Config from '../Config';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="footer footer-dark pt-5">
      <div className="footer-content">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 mb-2">
              <Link to={Config.LINKS.HOME} className="footer-logo">
                <img className="footer-logo-item" style={{ opacity: 0.4 }} alt="logo" src={Company.branding.logo.href} />
              </Link>
              <p className="mt-3">Wir bieten Ihnen innovative und funktionale Lösungen für die manuelle Datenerfassung Ihres Unternehmens.</p>
            </div>

            <div className="col-md-3 col-6 mt-3 mt-sm-0">
              <ul className="list-unstyled">
                <li className="media mb-3">
                  <i className="mr-3 display-8 fas fa-map-marked-alt"></i>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Company.address.display.replaceAll('\\n\\r', '<br />'),
                    }}
                  />
                </li>
                <li className="media mb-3">
                  <i className="mr-3 display-8 fas fa-phone-volume"></i>
                  {Company.contact.phone}
                </li>
                <li className="media mb-3">
                  <i className="mr-3 display-8 fas fa-envelope"></i>
                  {Company.contact.email}
                </li>
                <li className="media mb-3">
                  <i className="mr-3 display-8 fas fa-clock"></i>
                  <p> {Company.business_hours.display} </p>
                </li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mt-3 mt-sm-0">
              <h6 className="text-underline"> Navigation </h6>
              <div className="row">
                <div className="col-4">
                  <ul className="nav flex-column primary-hover">
                    <li className="nav-item">
                      <Link to={Config.LINKS.HOME} className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={Config.LINKS.IMPRINT} className="nav-link">
                        Impressum
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={Config.LINKS.DATAPOLICY} className="nav-link">
                        Datenschutz
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider mt-3"></div>

      <div className="footer-copyright py-3">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left">
            <div className="copyright-text">&copy; {`${new Date().getFullYear()} ${Company.name}`}</div>
            <div className="copyright-links primary-hover mt-3 mt-md-0">
              <ul className="list-inline">
                <li className="list-inline-item pl-2">
                  <Link to={Config.LINKS.IMPRINT} className="list-group-item-action">
                    Impressum
                  </Link>
                </li>
                <li className="list-inline-item pl-2">
                  <Link to={Config.LINKS.DATAPOLICY} className="list-group-item-action">
                    Datenschutz
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
