import { useEffect } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Company from '../Company.json';

import { setDocumentTitle } from '../Util';
import Config from '../Config';
import { Link } from 'react-router-dom';

export default function Imprint() {
  useEffect(() => {
    setDocumentTitle('Impressum');
  }, []);

  return (
    <div>
      <Navbar />

      <div className="innerpage-banner center bg-overlay-dark-7 py-7">
        <div className="container">
          <div className="row all-text-white">
            <div className="col-12">
              <h1 className="innerpage-title">Impressum</h1>

              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Impressum</li>
                <li className="breadcrumb-item">
                  <Link to={Config.LINKS.DATAPOLICY}>Datenschutz</Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="container py-5" style={{ fontSize: '1rem' }}>
        <span className="small">
          Zuletzt bearbeitet am: <span>2021-08-08</span>
        </span>

        <h5 className="mt-3 mb-3">Angaben nach § 5 TMG:</h5>

        <h6>Anbieter:</h6>

        <p>
          Geschäftsführer: {Company.ceo} <br />
          {Company.name}
        </p>

        <p
          className="my-4"
          dangerouslySetInnerHTML={{
            __html: Company.address.display_long.replaceAll('\\n\\r', '<br />'),
          }}
        ></p>

        <p>USt-ID: {Company.vat}</p>

        <h6>Kontaktmöglichkeiten:</h6>
        <p className="mb-1">Tel.: {Company.contact.phone}</p>
        <p className="mb-1">Fax: {Company.contact.fax}</p>
        <p>E-Mail: {Company.contact.email} </p>
      </section>

      <Footer />
    </div>
  );
}
