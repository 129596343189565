import Company from './Company.json';

/**
 * Set the document title with the company suffix automatically added.
 *
 * @param title The document title.
 */
export const setDocumentTitle = (title: string): void => {
  if (title === '') {
    window.document.title = Company.name;
  } else {
    window.document.title = `${title.trim()} :: ${Company.name}`;
  }
};

/**
 * Format the contact email to a callable uri.
 *
 * @returns The mailto uri.
 */
export const getMailToUri = (): string => {
  return 'mailto:' + Company.contact.email.replace(' [at] ', '@');
};

/**
 * Format the contact phone number to a callable uri.
 *
 * @returns The phone uri.
 */
export const getPhoneUri = (): string => {
  return 'phone://' + Company.contact.phone.replaceAll(' ', '').replaceAll('/', '').replaceAll('-', '');
};
