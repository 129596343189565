import React, { useEffect } from 'react';
import Company from '../Company.json';
import { NavLink, Link } from 'react-router-dom';
import Config from '../Config';
import { getMailToUri, getPhoneUri } from '../Util';
import deFlag from '../img/de.svg';
import 'bootstrap/js/src/collapse';

/**
 * Handle the scroll event.
 *
 * @param event The DOM scroll event.
 */
const handleScroll = (event: Event) => {
  const navbarSticky = document.querySelector('.navbar-sticky');

  if (navbarSticky) {
    if (window.scrollY > 400) {
      navbarSticky.classList.add('navbar-sticky-on');
    } else {
      navbarSticky.classList.remove('navbar-sticky-on');
    }
  }
};

export default function Navbar() {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <header className="header-static navbar-sticky navbar-light shadow">
        <div className="navbar-top">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <ul className="nav list-unstyled">
                  <li className="nav-item mr-3 d-none d-lg-block">
                    <img className="dropdown-item-icon" src={deFlag} alt="GER" />
                    Deutschland
                  </li>
                  <li className="nav-item mr-3">
                    <a className="navbar-link" href={getPhoneUri()}>
                      <b>Tel.:</b> {Company.contact.phone}
                    </a>
                  </li>
                  <li className="nav-item mr-3">
                    <a href={getMailToUri()} className="navbar-link">
                      <b>E-Mail:</b> {Company.contact.email}
                    </a>
                  </li>
                  <li className="nav-item">
                    <span className="navbar-link">
                      <b>Geschäftszeiten:</b> {Company.business_hours.display} Uhr
                    </span>
                  </li>
                </ul>
              </div>
              {/* <div className="d-flex align-items-center">
                  <img style={{ height: '24px' }} src={Company.branding.logo.href} alt={Company.name} />
                </div> */}
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link to={Config.LINKS.HOME} className="navbar-brand">
              <img className="navbar-brand-item" src={Company.branding.logo.href} alt={Company.name} />
            </Link>

            <button
              className="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"> </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink exact to={Config.LINKS.HOME} className="nav-link">
                    Home
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink exact to={Config.LINKS.IMPRINT} className="nav-link">
                    Impressum
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink exact to={Config.LINKS.DATAPOLICY} className="nav-link">
                    Datenschutz
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* <div className="navbar-nav pl-3">
                <div className="nav-item border-0 d-none d-lg-inline-block align-self-center">
                  <a href={getMailToUri()} className=" btn btn-sm btn-grad text-white mb-0">
                    E-Mail
                  </a>
                </div>
              </div> */}
          </div>
        </nav>
      </header>
    </div>
  );
}
